import {
  Component,OnChanges,Input,Output,EventEmitter,
} from '@angular/core';
import { CaptchaService } from '../../services/captcha.service';
import { Subject, takeUntil, timer } from 'rxjs';
@Component({
  selector: 'app-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.css'],
})
export class CaptchaComponent implements OnChanges {
  @Input('config') config: any = {};
  @Output() captchaCode = new EventEmitter();
  captch_input: any = null;
  code: any = null;
  resultCode: any = null;
  captchaQuestionText = '';
  unsubscribe$ = new Subject<void>();
  constructor(private captchService: CaptchaService) {}
  ngOnChanges() {
    if (this.config) {
      if (!this.config.font || !this.config.font.size) {
        this.config['font']['size'] = '400px';
      }
      if (!this.config.font || !this.config.font.family) {
        this.config['font']['family'] = 'Arial';
      }
      if (!this.config.strokeColor) {
        this.config['strokeColor'] = 'red';
      }
      if (!this.config.length) {
        this.config['length'] = 6;
      }
      if (!this.config.cssClass) {
        this.config['cssClass'] = '';
      }

      if (!this.config.type) {
        this.config['type'] = 4;
      }

      if (!this.config.back || !this.config.back.stroke) {
        this.config['back']['stroke'] = '';
      }
      if (!this.config.back || !this.config.back.solid) {
        this.config['back']['solid'] = 'red';
      }

      this.createCaptcha();
    }
  }
  getRandomIntInclusive = (min: any, max: any) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
  };
  isDotCaptcha() {
    return this.config.type == 3;
  }
  generateSmallBox(position: any, ctx: any) {
    const midBoxPosition = {
      x: this.getRandomIntInclusive(
        position.x + 3,
        position.x + position.width - 6
      ),
      y: this.getRandomIntInclusive(
        position.y + 3,
        position.y + position.height - 6
      ),
      width: 6,
      height: 6,
    };
    ctx.fillRect(
      midBoxPosition.x,
      midBoxPosition.y,
      midBoxPosition.width,
      midBoxPosition.height
    );
    return midBoxPosition;
  }
  getMousePosition(captchaCanvas: any, event: any) {
    const rect = captchaCanvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    return {
      x,
      y,
    };
  }
  isCountCaptcha() {
    return this.config.type == 4;
  }
  createCaptcha() {
    this.captch_input = '';
    if (this.config.type == 1) {
      // text captcha
      this.code = this.resultCode =
        Math.random().toString(24).substring(2, this.config.length) +
        Math.random().toString(24).substring(2, 4).toUpperCase();
    } else if (this.config.type == 2) {
      // calculation captcha
      const num1 = Math.floor(Math.random() * 99);
      const num2 = Math.floor(Math.random() * 9);
      const operators = ['+', '-'];
      const operator = operators[Math.floor(Math.random() * operators.length)];
      this.code = num1 + operator + num2 + '=?';
      this.resultCode = operator == '+' ? num1 + num2 : num1 - num2;
    } else if (this.config.type == 3) {
      // dot captcha
    } else if (this.config.type == 4) {
      // count captcha
      const symbols = [
        { key: 'clouds', value: '☁' },
        { key: 'telephones', value: '☎' },
        { key: 'stars', value: '★' },
        { key: 'umbrellas', value: '☂' },
        { key: 'smiles', value: '☺' },
        { key: 'scissors', value: '✂' },
        { key: 'aeroplanes', value: '✈' },
        { key: 'trucks', value: '⛟' },
        { key: 'mountains', value: '⛰' },
      ];
      const resultIndex = this.getRandomIntInclusive(0, symbols.length - 1);
      this.resultCode = {
        value: this.getRandomIntInclusive(1, 3),
        key: symbols[resultIndex].key,
      };
      this.code = '';
      for (let i = 0; i < this.resultCode.value; i++) {
        this.code += symbols[resultIndex].value;
      }
      symbols.splice(resultIndex, 1);
      symbols.sort(() => 0.5 - Math.random());
      for (let i = 0; i < this.config.length - this.resultCode.value; i++) {
        this.code += symbols[i].value;
      }
      this.code = Array.from(this.code).sort(() => 0.5 - Math.random()).join('');

    }
    timer(300)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(data =>{
      if (typeof document !== 'undefined') {
        const captchaCanvas: any = document.getElementById('captchaCanvas');
        const canvasPosition = {
          x: 0,
          y: 0,
          width: captchaCanvas.width,
          height: captchaCanvas.height,
        };
        const ctx = captchaCanvas.getContext('2d');
        ctx.fillStyle = this.config.back.solid;
        ctx.fillRect(
          canvasPosition.x,
          canvasPosition.y,
          canvasPosition.width,
          canvasPosition.height
        );
        ctx.beginPath();
        if (this.isDotCaptcha()) {
          ctx.fillStyle = '#000';
          const position = {
            x: 20,
            y: 20,
            width: 30,
            height: 30,
            thickness: 1,
          };
          // tslint:disable-next-line:max-line-length
          ctx.fillRect(
            position.x - position.thickness,
            position.y - position.thickness,
            position.width + position.thickness * 2,
            position.height + position.thickness * 2
          );
          ctx.fillStyle = '#FFF';
          ctx.fillRect(position.x, position.y, position.width, position.height);
          ctx.font = '18px ' + this.config.font.family;
          ctx.fillStyle = this.config.font.color;
          ctx.fillText(
            "I'm not a Robot",
            position.x + position.width + 10,
            position.y * 2 - 4
          );
          ctx.font = 'bold 12px ' + this.config.font.family;
          ctx.fillStyle = this.config.font.color;
          ctx.fillText(
            'Click on all dots...',
            position.x + position.width + 10,
            position.y * 2 + 12
          );
          const midBox1 = {
            success: false,
            position: this.generateSmallBox(position, ctx),
          };
          const midBox2 = {
            success: false,
            position: this.generateSmallBox(position, ctx),
          };
          let clickCount = 0;
          captchaCanvas.addEventListener('mousedown', (e: any) => {
            clickCount += 1;
            const clickPosition = this.getMousePosition(captchaCanvas, e);
            this.resultCode = 1;
            if (
              clickPosition.x >= midBox1.position.x &&
              clickPosition.x <= midBox1.position.x + midBox1.position.width &&
              clickPosition.y >= midBox1.position.y &&
              clickPosition.y <= midBox1.position.y + midBox1.position.height
            ) {
              midBox1.success = true;
            }
            if (
              clickPosition.x >= midBox2.position.x &&
              clickPosition.x <= midBox2.position.x + midBox2.position.width &&
              clickPosition.y >= midBox2.position.y &&
              clickPosition.y <= midBox2.position.y + midBox2.position.height
            ) {
              midBox2.success = true;
            }
            if (clickCount === 2) {
              if (midBox1.success && midBox2.success) {
                this.captch_input = 1;
                ctx.font = '25px ' + this.config.font.family;
                ctx.fillStyle = '#158a0d';
                ctx.fillText(
                  '✓',
                  position.x + 5,
                  position.y + position.height - 5
                );
              } else {
                // it is a human
                this.captch_input = 0;
                this.createCaptcha();
              }
              this.checkCaptcha();
            }
          });
        } else if (this.isCountCaptcha()) {
          this.captchaQuestionText =
            'How many "' + this.resultCode.key + '" can you see?';
          captchaCanvas.style.letterSpacing = 1 + 'px';
          ctx.font = '12px' + ' ' + this.config.font.family;
          ctx.fillStyle = this.config.font.color;
          ctx.fillText(this.captchaQuestionText, 0, canvasPosition.height - 5);
          let lastX = 0;
          ctx.font = '23px' + ' ' + this.config.font.family;
          ctx.fillStyle = this.config.font.color;
          let lastY = 60;
          Array.from(this.code).forEach((c, index) => {
            lastY = this.getRandomIntInclusive(
              canvasPosition.y + 30,
              canvasPosition.y + canvasPosition.height - 20
            );
            lastX += 32;
            ctx.fillText(c, lastX, lastY);
          });
          this.resultCode = this.resultCode.value;
        } else {
          captchaCanvas.style.letterSpacing = 15 + 'px';
          ctx.font = this.config.font.size + ' ' + this.config.font.family;
          ctx.fillStyle = this.config.font.color;
          let lastX = 0;
          let lastY = 60;
          Array.from(this.code).forEach((c, index) => {
            if (this.config.type != 2) {
              lastY = this.getRandomIntInclusive(
                canvasPosition.y + 30,
                canvasPosition.y + canvasPosition.height - 10
              );
            }
            lastX += 32;
            ctx.fillText(c, lastX, lastY);
          });
          if (this.config.back.stroke) {
            ctx.strokeStyle = this.config.back.stroke;
            for (let i = 0; i < 150; i++) {
              ctx.moveTo(Math.random() * 300, Math.random() * 300);
              ctx.lineTo(Math.random() * 300, Math.random() * 300);
            }
            ctx.stroke();
          }
          this.captchaQuestionText = this.code.split('').join(' ');
        }
      }
    
  });
}

  playCaptcha() {
    var msg = new SpeechSynthesisUtterance(this.captchaQuestionText);
    msg.pitch = 0.1; // Set pitch of speech
    window.speechSynthesis.speak(msg); // Speak the message aloud
  }

  checkCaptcha() {
    if (this.captch_input != this.resultCode) {
      this.captchService.setCaptchaStatus(false);
    } else {
      this.captchService.setCaptchaStatus(true);
    }
  }
}
